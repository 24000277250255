const requiredVar = (value: string | undefined, name: string): string => {
    if (value === undefined || value === '') {
        throw new Error('Missing required environment variable: ' + name);
    }
    return value;
};

const config = {
    API_URL: requiredVar(process.env.NEXT_PUBLIC_API_URL, 'NEXT_PUBLIC_API_URL'),
    IMAGES_BUCKET: requiredVar(process.env.NEXT_PUBLIC_IMAGES_BUCKET, 'NEXT_PUBLIC_IMAGES_BUCKET'),
    MONRI_TOKEN: requiredVar(process.env.NEXT_PUBLIC_MONRI_TOKEN, 'NEXT_PUBLIC_MONRI_TOKEN'),
    MONRI_URL: requiredVar(process.env.NEXT_PUBLIC_MONRI_URL, 'NEXT_PUBLIC_MONRI_URL'),
    FIREBASE_API_KEY: requiredVar(process.env.NEXT_PUBLIC_FIREBASE_API_KEY, 'NEXT_PUBLIC_FIREBASE_API_KEY'),
    FIREBASE_AUTH_DOMAIN: requiredVar(process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN, 'NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN'),
    FIREBASE_PROJECT_ID: requiredVar(process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID, 'NEXT_PUBLIC_FIREBASE_PROJECT_ID'),
    FIREBASE_STORAGE_BUCKET: requiredVar(process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET, 'NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET'),
    FIREBASE_MESSAGING_SENDER_ID: requiredVar(process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID, 'NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID'),
    FIREBASE_APP_ID: requiredVar(process.env.NEXT_PUBLIC_FIREBASE_APP_ID, 'NEXT_PUBLIC_FIREBASE_APP_ID'),
    FIREBASE_CHAT_DB_NAME: requiredVar(process.env.NEXT_PUBLIC_FIREBASE_CHAT_DB_NAME, 'NEXT_PUBLIC_FIREBASE_CHAT_DB_NAME'),
    FIREBASE_MEASUREMENT_ID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    ENVIRONMENT: process.env.NODE_ENV,
    SENTRY: process.env.NEXT_PUBLIC_SENTRY,
    FIXED_FILTER_CAR_MANUFACTURER_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_CAR_MANUFACTURER_ID, 'NEXT_PUBLIC_FIXED_FILTER_CAR_MANUFACTURER_ID'),
    FIXED_FILTER_CAR_MODEL_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_CAR_MODEL_ID, 'NEXT_PUBLIC_FIXED_FILTER_CAR_MODEL_ID'),
    FIXED_FILTER_CAR_YEAR_OF_PRODUCTION_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_CAR_YEAR_OF_PRODUCTION_ID, 'NEXT_PUBLIC_FIXED_FILTER_CAR_YEAR_OF_PRODUCTION_ID'),
    FIXED_FILTER_BIKE_MANUFACTURER_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_BIKE_MANUFACTURER_ID, 'NEXT_PUBLIC_FIXED_FILTER_BIKE_MANUFACTURER_ID'),
    FIXED_FILTER_BIKE_MODEL_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_BIKE_MODEL_ID, 'NEXT_PUBLIC_FIXED_FILTER_BIKE_MODEL_ID'),
    FIXED_FILTER_BIKE_YEAR_OF_PRODUCTION_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_BIKE_YEAR_OF_PRODUCTION_ID, 'NEXT_PUBLIC_FIXED_FILTER_BIKE_YEAR_OF_PRODUCTION_ID'),
    FIXED_FILTER_PARTS_MANUFACTURER_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_PARTS_MANUFACTURER_ID, 'NEXT_PUBLIC_FIXED_FILTER_PARTS_MANUFACTURER_ID'),
    FIXED_FILTER_PARTS_MODEL_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_PARTS_MODEL_ID, 'NEXT_PUBLIC_FIXED_FILTER_PARTS_MODEL_ID'),
    FIXED_FILTER_PARTS_YEAR_OF_PRODUCTION_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_PARTS_YEAR_OF_PRODUCTION_ID, 'NEXT_PUBLIC_FIXED_FILTER_PARTS_YEAR_OF_PRODUCTION_ID'),
    FIXED_FILTER_PHONE_MANUFACTURER_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_PHONE_MANUFACTURER_ID, 'NEXT_PUBLIC_FIXED_FILTER_PHONE_MANUFACTURER_ID'),
    FIXED_FILTER_PHONE_MODEL_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_PHONE_MODEL_ID, 'NEXT_PUBLIC_FIXED_FILTER_PHONE_MODEL_ID'),
    FIXED_FILTER_TABLET_MANUFACTURER_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_TABLET_MANUFACTURER_ID, 'NEXT_PUBLIC_FIXED_FILTER_TABLET_MANUFACTURER_ID'),
    FIXED_FILTER_TABLET_MODEL_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_TABLET_MODEL_ID, 'NEXT_PUBLIC_FIXED_FILTER_TABLET_MODEL_ID'),
    FIXED_FILTER_LAPTOP_MANUFACTURER_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_LAPTOP_MANUFACTURER_ID, 'NEXT_PUBLIC_FIXED_FILTER_LAPTOP_MANUFACTURER_ID'),
    FIXED_FILTER_LAPTOP_MODEL_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_LAPTOP_MODEL_ID, 'NEXT_PUBLIC_FIXED_FILTER_LAPTOP_MODEL_ID'),
    FIXED_FILTER_REAL_ESTATE_APARTMENTS_TYPE_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_APARTMENTS_TYPE_ID, 'NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_APARTMENTS_TYPE_ID'),
    FIXED_FILTER_REAL_ESTATE_APARTMENTS_SIZE_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_APARTMENTS_SIZE_ID, 'NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_APARTMENTS_SIZE_ID'),
    FIXED_FILTER_REAL_ESTATE_HOUSES_TYPE_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_HOUSES_TYPE_ID, 'NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_HOUSES_TYPE_ID'),
    FIXED_FILTER_REAL_ESTATE_HOUSES_SIZE_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_HOUSES_SIZE_ID, 'NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_HOUSES_SIZE_ID'),
    FIXED_FILTER_REAL_ESTATE_LAND_TYPE_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_LAND_TYPE_ID, 'NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_LAND_TYPE_ID'),
    FIXED_FILTER_REAL_ESTATE_LAND_SIZE_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_LAND_SIZE_ID, 'NEXT_PUBLIC_FIXED_FILTER_REAL_ESTATE_LAND_SIZE_ID'),
    FIXED_FILTER_RENT_A_CAR_MODEL_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_RENT_A_CAR_MODEL_ID, 'NEXT_PUBLIC_FIXED_FILTER_RENT_A_CAR_MODEL_ID'),
    FIXED_FILTER_RENT_A_CAR_MANUFACTURER_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_RENT_A_CAR_MANUFACTURER_ID, 'NEXT_PUBLIC_FIXED_FILTER_RENT_A_CAR_MANUFACTURER_ID'),
    FIXED_FILTER_RENT_A_CAR_YEAR_OF_PRODUCTION_ID: requiredVar(process.env.NEXT_PUBLIC_FIXED_FILTER_RENT_A_CAR_YEAR_OF_PRODUCTION_ID, 'NEXT_PUBLIC_FIXED_FILTER_RENT_A_CAR_YEAR_OF_PRODUCTION_ID'),
    SUBSCRIPTION_PLAN_PREMIUM: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM'),
    SUBSCRIPTION_PLAN_PREMIUM_SIX_MONTHS: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_SIX_MONTHS, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_SIX_MONTHS'),
    SUBSCRIPTION_PLAN_PREMIUM_YEARLY: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_YEARLY, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_YEARLY'),
    SUBSCRIPTION_PLAN_PREMIUM_VARIANT: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_VARIANT, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_VARIANT'),
    SUBSCRIPTION_PLAN_PREMIUM_VARIANT_SIX_MONTHS: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_VARIANT_SIX_MONTHS, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_VARIANT_SIX_MONTHS'),
    SUBSCRIPTION_PLAN_PREMIUM_VARIANT_YEARLY: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_VARIANT_YEARLY, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_VARIANT_YEARLY'),
    SUBSCRIPTION_PLAN_PREMIUM_ID: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_ID, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_ID'),
    SUBSCRIPTION_PLAN_PREMIUM_SIX_MONTHS_ID: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_SIX_MONTHS_ID, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_SIX_MONTHS_ID'),
    SUBSCRIPTION_PLAN_PREMIUM_YEARLY_ID: requiredVar(process.env.NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_YEARLY_ID, 'NEXT_PUBLIC_SUBSCRIPTION_PLAN_PREMIUM_YEARLY_ID'),
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    GOOGLE_VERIFICATION_ID: requiredVar(process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_ID, 'NEXT_PUBLIC_GOOGLE_VERIFICATION_ID'),
    APP_DOMAIN: requiredVar(process.env.NEXT_PUBLIC_APP_DOMAIN, 'NEXT_PUBLIC_APP_DOMAIN'),
};

export default config;
